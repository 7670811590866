export const configuration = {
  language: {
    default: 'en'
  },
  api: {
    publicEndpoints: [
      '/login',
      '/auth/forgot-password',
      '/auth/token/check',
      '/auth/restore-password',
      '/auth/temporary-code/check',
      '/register'
    ],
    refreshTokenEndpoint: '/auth/refresh'
  },
  cookies: {
    keys: {
      isAuthenticated: 'is_authenticated'
    }
  },
  passwordRecoveryTimeout: 60 * 1000,
  dateTimeFormat: {
    time: 'mm:ss',
    date: 'd MMM',
    apiDateTime: 'yyyy-MM-dd HH:mm:ss'
  },
  fieldMask: {
    phone: {
      mask: '{+44}0000000000'
    }
  },
  map: {
    apiRoot: 'https://maps.googleapis.com/maps/api/',
    defaultCoords: {
      latitude: 54.4771522,
      longitude: -3.4253712
    },
    defaultZoom: 14,
    propertySurroundingsZoom: 12,
    administrativeAreaLevelTwoZoom: 10,
    initialZoom: 5
  },
  links: {
    privacyPolicy: 'https://ukretreats.co.uk/privacy-policy',
    termsOfService: 'https://ukretreats.co.uk/terms-of-service'
  },
  filters: {
    minPossiblePrice: 0,
    maxPossiblePrice: 900
  }
};

import { values } from 'lodash';
import { combineEpics, Epic } from 'redux-observable';
import { newPasswordPageEpics } from '@app/account-access/new-password/shared/store/epics';
import { passwordRecoveryPageEpics } from '@app/account-access/password-recovery/shared/store/epics';
import { signupPageEpics } from '@app/account-access/signup/shared/store/epics';
import { changePasswordPageEpics } from '@app/main/change-password/shared/store/epics';
import { editProfilePageEpics } from '@app/main/edit-profile/shared/store/epics';
import { homePageEpics } from '@app/main/home/shared/store/epics';
import { premiumPageEpics } from '@app/main/premium/shared/store/epics';
import { propertiesGroupPageEpics } from '@app/main/properties-group/shared/store/epics';
import { propertyPageEpics } from '@app/main/property/shared/store/epics';
import { rewardsPageEpics } from '@app/main/rewards/shared/store/epics';
import { availableDatesModalEpics } from '@app/main/shared/available-dates-modal/store/epics';
import { categoriesMultiselectEpics } from '@app/main/shared/categories-multiselect/store/epics';
import { profileFormEpics } from '@app/main/shared/profile-form/store/epics';
import { propertyTypesMultiselectEpics } from '@app/main/shared/property-types-multiselect/store/epics';
import { searchModalEpics } from '@app/main/shared/search-modal/store/epics';
import { authEpics } from '@shared/auth/store/epics';
import { profileEpics } from '@shared/profile/store/epics';

export const rootEpic = combineEpics(
  ...values<Epic>(profileEpics),
  ...values<Epic>(authEpics),
  ...values<Epic>(passwordRecoveryPageEpics),
  ...values<Epic>(newPasswordPageEpics),
  ...values<Epic>(signupPageEpics),
  ...values<Epic>(profileFormEpics),
  ...values<Epic>(editProfilePageEpics),
  ...values<Epic>(changePasswordPageEpics),
  ...values<Epic>(categoriesMultiselectEpics),
  ...values<Epic>(searchModalEpics),
  ...values<Epic>(homePageEpics),
  ...values<Epic>(propertiesGroupPageEpics),
  ...values<Epic>(propertyPageEpics),
  ...values<Epic>(availableDatesModalEpics),
  ...values<Epic>(premiumPageEpics),
  ...values<Epic>(rewardsPageEpics),
  ...values<Epic>(propertyTypesMultiselectEpics)
);

import { of } from 'rxjs';
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { AuthSelectors } from '@shared/auth';
import i18n from '@shared/i18n/i18n';
import { settingService } from '@shared/setting';
import { toastService } from '@shared/toast';
import { AppActions } from '@store/actions';
import { storeRef } from '@store/store-ref';
import { Epics } from '@store/types';
import { profileService } from '../service';
import { ProfileActions } from './actions';

export const profileEpics: Epics = {
  getLocation: (action$) => action$.pipe(
    filter(ProfileActions.getLocation.match),
    tap(() => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          ({ coords }) => storeRef.dispatch?.(ProfileActions.getLocationSuccess(coords)),
          () => {
            storeRef.dispatch?.(ProfileActions.getLocationFailure());
          },
          { enableHighAccuracy: true }
        );
      } else {
        toastService.show(i18n.t('COMMON.TOASTS.TEXT_NO_GEOLOCATION'), 'error');
      }
    }),
    map(() => AppActions.noop())
  ),

  refreshProfile: (action$, state$) => action$.pipe(
    filter(ProfileActions.refreshProfile.match),
    filter(() => AuthSelectors.isAuthenticated(state$.value)),
    exhaustMap(() => profileService.getProfile({ relations: ['subscription'] }).pipe(
      map((user) => ProfileActions.refreshProfileSuccess(user)),
      catchError((error) => of(ProfileActions.refreshProfileFailure(error)))
    ))
  ),

  loadSettings: (action$, state$) => action$.pipe(
    filter(ProfileActions.loadSettings.match),
    filter(() => AuthSelectors.isAuthenticated(state$.value)),
    exhaustMap(() => settingService.search({ all: true }).pipe(
      map((response) => ProfileActions.loadSettingsSuccess(response.data)),
      catchError((error) => of(ProfileActions.loadSettingsFailure(error)))
    ))
  )
};

import { Expose, Type } from 'class-transformer';
import { DateTime } from 'luxon';
import { BaseEntity } from '@shared/base-entity/models';
import { TransformBoolean, TransformDate } from '@shared/class-transformer';
import { Media } from '@shared/media';
import { Property } from '@shared/property/models';

export class Discount extends BaseEntity<number> {
  @Expose()
  public title: string;

  @Expose()
  public subtitle: string;

  @Expose({ name: 'promo_code' })
  public promoCode: string;

  @Expose()
  @Type(() => Media)
  public photo: Media;

  @Expose()
  public website: string;

  @Expose({ name: 'is_premium' })
  @TransformBoolean()
  public isPremium: string;

  @Expose()
  @Type(() => Property)
  public property: Property;

  @Expose({ name: 'date_start' })
  @Type(() => DateTime)
  @TransformDate()
  public startDate: DateTime;

  @Expose({ name: 'date_end' })
  @Type(() => DateTime)
  @TransformDate()
  public endDate: DateTime;

  @Expose({ name: 'dates_text' })
  public datesText: string;

  @Expose({ name: 'saving_amount' })
  public savingAmount: string;

  @Expose()
  public terms: string;

  constructor(model: Partial<Discount>) {
    super(model);

    Object.assign(this, model);
  }
}

import { baseListedEntityEpics } from '@shared/base-listed-entity-store';
import { Discount, discountService } from '@shared/discount';
import { Epics } from '@store/types';
import { rewardsPageActions } from './actions';
import { rewardsPageSelectors } from './selectors';
import { RewardsPageState } from './state';

export const rewardsPageEpics: Epics = baseListedEntityEpics<Discount, RewardsPageState>(
  rewardsPageActions,
  rewardsPageSelectors,
  discountService
);

import { isAnyOf } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ProfileSelectors } from '@shared/profile';
import { searchService, SearchPaginationRequest } from '@shared/search';
import { Epics } from '@store/types';
import { HomeView } from '../enums';
import { HomePageActions } from './actions';
import { HomePageSelectors } from './selectors';

function transformParams(filters: SearchPaginationRequest, view: HomeView): SearchPaginationRequest {
  if (view === HomeView.LIST) {
    return omit(filters, ['topRightLat', 'topRightLng', 'bottomLeftLat', 'bottomLeftLng']);
  }

  return omit(filters, ['orderBy', 'desc']);
}

export const homePageEpics: Epics = {
  loadItems: (action$, state$) => action$.pipe(
    filter(HomePageActions.loadItems.match),
    switchMap((action) => {
      const view = HomePageSelectors.view(state$.value);
      const profileCoords = ProfileSelectors.coords(state$.value);

      const params = {
        ...(HomePageSelectors.filters(state$.value) || {}),
        ...('payload' in action ? action.payload : {})
      };

      if (params.orderBy === 'distance') {
        params.profileLatitude = profileCoords.latitude;
        params.profileLongitude = profileCoords.longitude;
      }

      return searchService.search(transformParams(params, view)).pipe(
        map((response) => HomePageActions.loadItemsSuccess(response)),
        catchError((error) => of(HomePageActions.loadItemsFailure(error)))
      );
    })
  ),

  changeFilter: (action$) => action$.pipe(
    filter(HomePageActions.changeFilter.match),
    debounceTime(600),
    map(() => HomePageActions.loadItems({ page: 1 }))
  ),

  resetFilter: (action$) => action$.pipe(
    filter(isAnyOf(HomePageActions.resetFilter, HomePageActions.toggleView)),
    map(() => HomePageActions.loadItems({ page: 1 }))
  )
};

import './app.scss';
import 'reflect-metadata';
import React, { PropsWithChildren, ReactElement, Suspense, useEffect } from 'react';
import { isDesktop } from 'react-device-detect';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { GetScrollRestorationKeyFunction, Route, Routes, ScrollRestoration, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { show } from 'redux-modal';
import { TabBarLayout } from '@shared//tab-bar-layout';
import { AuthSelectors } from '@shared/auth/store/selectors';
import { AuthRoute } from '@shared/auth-route';
import { DesktopWarningModal } from '@shared/desktop-warning-modal';
import { Icon } from '@shared/icon';
import { lazyWithRetry } from '@shared/lazy-with-retry';
import { ModalID } from '@shared/modal';
import { AppActions } from '@store/actions';

const IntroPage = lazyWithRetry(() => import('./account-access/intro/intro'), 'IntroPage');
const LoginPage = lazyWithRetry(() => import('./account-access/login/login'), 'LoginPage');
const PasswordRecoveryPage = lazyWithRetry(
  () => import('./account-access/password-recovery/password-recovery'),
  'PasswordRecoveryPage'
);
const NewPasswordPage = lazyWithRetry(() => import('./account-access/new-password/new-password'), 'NewPasswordPage');
const SignupPage = lazyWithRetry(() => import('./account-access/signup/signup'), 'SignupPage');
const CompleteProfilePage = lazyWithRetry(
  () => import('./main/complete-profile/complete-profile'),
  'CompleteProfilePage'
);
const HomePage = lazyWithRetry(() => import('./main/home/home'), 'HomePage');
const ProfilePage = lazyWithRetry(() => import('./main/profile/profile'), 'ProfilePage');
const ChangePasswordPage = lazyWithRetry(() => import('./main/change-password/change-password'), 'ChangePasswordPage');
const EditProfilePage = lazyWithRetry(() => import('./main/edit-profile/edit-profile'), 'EditProfilePage');
const PropertyPage = lazyWithRetry(() => import('./main/property/property'), 'PropertyPage');
const PropertiesGroupPage = lazyWithRetry(
  () => import('./main/properties-group/properties-group'),
  'PropertiesGroupPage'
);
const NotFoundPage = lazyWithRetry(() => import('./not-found/not-found'), 'NotFoundPage');
const RewardsPage = lazyWithRetry(() => import('./main/rewards/rewards'), 'RewardsPage');
const LastMinuteEscapesPage = lazyWithRetry(
  () => import('@app/main/last-minute-escapes/last-minute-escapes'),
  'LastMinuteEscapesPage'
);
const PremiumPage = lazyWithRetry(() => import('./main/premium/premium'), 'PremiumPage');

ReactModal.setAppElement('#root');

const ComponentWithChildren = (props: PropsWithChildren): ReactElement => <div>{props.children}</div>;

export function App(): ReactElement {
  const isAuthenticated = useSelector(AuthSelectors.isAuthenticated);
  const initialPrivatePage = useSelector(AuthSelectors.initialPrivatePage);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getScrollRestorationKey: GetScrollRestorationKeyFunction = (location) => location.pathname;

  useEffect(() => {
    if (isDesktop) {
      dispatch(show(ModalID.DESKTOP_WARNING));
    }
    dispatch(AppActions.init());
  }, []);

  useEffect(() => {
    if (initialPrivatePage) {
      navigate(isAuthenticated ? initialPrivatePage : '/');
    }
  }, [isAuthenticated]);

  return (
    <div className='app'>
      <ToastContainer
        position='bottom-center'
        hideProgressBar
        newestOnTop
        closeOnClick
        closeButton={
          <ComponentWithChildren>
            <div className='Toastify__toast-close'>
              <Icon variant='close' />
            </div>
          </ComponentWithChildren>
        }
        theme='light'
      />
      <Routes>
        <Route element={<AuthRoute isAuthenticated={isAuthenticated} />}>
          <Route path='/' element={<IntroPage />} />
          <Route path='/login' element={<LoginPage />} />
          <Route path='/password-recovery' element={<PasswordRecoveryPage />} />
          <Route path='/enter-new-password/:token' element={<NewPasswordPage />} />
          <Route path='/signup' element={<SignupPage />} />
        </Route>

        <Route element={<AuthRoute isPrivate isAuthenticated={isAuthenticated} />}>
          <Route element={<TabBarLayout />}>
            <Route path='/home' element={<HomePage />} />
            <Route path='/rewards' element={<RewardsPage />} />
            <Route path='/last-minute-escapes' element={<LastMinuteEscapesPage />} />
            <Route path='/profile' element={<ProfilePage />} />
          </Route>

          <Route path='/complete-profile' element={<CompleteProfilePage />} />
          <Route path='/change-password' element={<ChangePasswordPage />} />
          <Route path='/edit-profile' element={<EditProfilePage />} />
          <Route path='/property/:id' element={<PropertyPage />} />
          <Route path='/properties-group/:id' element={<PropertiesGroupPage />} />
          <Route path='/premium' element={<PremiumPage />} />
        </Route>

        <Route
          path='*'
          element={
            <Suspense>
              <NotFoundPage />
            </Suspense>
          }
        />
      </Routes>
      <ScrollRestoration getKey={getScrollRestorationKey} />
      <DesktopWarningModal />
    </div>
  );
}
